import * as React from 'react';
import GameCard from './GameCard';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import RacingService from '@/services/RacingService';
import { useEffect, useState } from 'react';

// const cards = [
//   {
//     id: 1,
//     title: 'Int’l Horse Racing',
//     url: '/game/provider/racing',
//     img: 'https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/horse-racing-img.png',
//     desc: 'Featuring international race clubs.',
//   },
//   {
//     id: 2,
//     title: 'XO BOXING',
//     url: '/game/provider/boxing',
//     img: 'https://arionplay-media.s3.ap-southeast-1.amazonaws.com/img/boxing1.png',
//     desc: "Is your fighter tough enough? Let's find out.",
//   },
//   {
//     id: 3,
//     title: 'SBA Season 1: Quezon City Dragons VS Taguig Stallions',
//     url: '/game/provider/billiard',
//     img: 'https://arionplay-media.s3.ap-southeast-1.amazonaws.com/img/billiard.jpeg',
//     desc: "Is your fighter tough enough? Let's find out.",
//   },
// ];
export default function XOGameCards() {
  const [cards, setCards] = useState([]);
  const getRaceEventTiles = async () => {
    const response = await RacingService.getRaceTiles();
    setCards(response.data.body);
  };

  useEffect(() => {
    getRaceEventTiles();
  }, []);

  return (
    <Grid container spacing={{ xs: 1, md: 1 }}>
      <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
        <Typography
          gutterBottom
          variant='h5'
          component='div'
          style={{ marginBottom: 0 }}
        >
          XO Games
        </Typography>
      </Grid>
      {cards &&
        cards.map((item: any) => (
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            key={item.id}
            sx={{ marginBottom: '30px' }}
          >
            <GameCard detail={item} />
          </Grid>
        ))}
    </Grid>
  );
}
